import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const WafdBankBenefits = (props) => {
  const IconsData = {
    icon1: "/images/icons/ribbon.svg",
    icon2: "/images/icons/house-with-heart.svg",
    icon3: "/images/icons/laptop-and-phone-with-24-hour-clock.svg"
  };
  const benefitsData = [
    {
      image: IconsData.icon1,
      altText: "Ribbon icon",
      text: "We keep your loan! We don't sell it to Wall Street or Fannie Mae.",
      esText: "Mantenemos tu préstamo! No se lo vendemos a Wall Street ni a Fannie Mae."
    },
    {
      image: IconsData.icon2,
      altText: "House Heart icon",
      text: "We have competitive mortgage rates and will be with you every step.",
      esText: "Tenemos tasas hipotecarias competitivas y estaremos con usted en cada paso."
    },
    {
      image: IconsData.icon3,
      altText: "Computer Clock icon",
      text: "You can quickly and securely apply online in minutes.",
      esText: "Puede presentar su solicitud en línea de forma rápida y segura en minutos."
    }
  ];
  return (
    <section className="container">
      <div className="h2 text-md-center">
        {props.isSpanish ? "Beneficios de trabajar con WaFd Bank" : "Benefits of working with WaFd Bank"}
      </div>
      <div className="row d-none d-md-flex mt-md-4">
        {benefitsData.map((obj, index) => (
          <div key={index} className="col-md-4 text-center">
            {/* <GatsbyImage image={obj.image} alt={obj.altText} /> */}
            <img alt="" width={96} height={96} src={obj.image} />
            <div className="col pt-3 font-weight-bold">{props.isSpanish ? obj.esText : obj.text}</div>
          </div>
        ))}
      </div>

      {benefitsData.map((obj, index) => (
        <div key={index} className="d-flex d-md-none mb-3">
          <div className="col-auto mr-3">
            {/* <GatsbyImage image={obj.imageXs} alt={obj.altText} /> */}
            <img alt="" width={64} height={64} src={obj.image} />
          </div>
          <div className="d-flex align-items-center font-weight-bold">{props.isSpanish ? obj.esText : obj.text}</div>
        </div>
      ))}
    </section>
  );
};

export default WafdBankBenefits;
